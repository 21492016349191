<template>
  <r-app :key="$r.lang" :dark="false">
    <div
      class="page-loading d-flex h-center v-center color-warning-text flex-column"
      v-if="!all_lang_loaded || !$r.store.user_loaded"
    >
      <r-progress-circular
        size="100"
        indeterminate
        width="4"
      ></r-progress-circular>
      <span class="mt-2">{{ $t("loading") }}</span>
    </div>
    <r-content v-else>
      <router-view />
    </r-content>
  </r-app>
</template>
<script>
import { App } from "./mixins/app";
export default {
  mixins: [App],
  created() {
    this.userInfo();
    this.$r.store.langs_list = {
      en: { rtl: false },
      fa: { rtl: true },
      ru: { rtl: false }, //Russian
      ku: { rtl: true },
      tr: { rtl: false }, //Turkish
      zh: { rtl: false }, //chinese
      de: { rtl: false }, //german
      ar: { rtl: true },
      hi: { rtl: false }, //india
      es: { rtl: false }, //spain
    };
    this.$r.store.unit = "toman";
  },
};
</script>
<style lang="scss" scoped>
.r-app.app-light {
  background-color: white;
}
.page-loading {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-image: linear-gradient(45deg, #332665, #0ceae3);
  z-index: 100;
}
</style>

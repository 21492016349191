<template>
  <div class="home-footer">
    <r-container>
      <r-row>
        <r-col class="col-12 md-4">
          <r-img
            @click.prevent="show = true"
            class="br-md logo-footer"
            src="/storage/img/site/logo.png"
            alt="mallimin"
            width="200"
            height="100"
          ></r-img>
          <p>{{ $t("home_about_msg") }}</p>
        </r-col>
        <r-col class="col-12 md-8">
          <div class="title">{{ $t("contact_us") }}</div>
          <div class="my-1" v-for="(item, k) in contact_us" :key="k">
            {{ $t(k) }}: {{ item }}
          </div>

          <div>
            <r-btn
              v-for="(item, i) in social_media"
              :key="i"
              icon
              class="ma-2 color-primary-text"
              v-title="item['name']"
              :href="item['url']"
              target="_blank"
            >
              <r-icon :class="item['icon']" exact></r-icon>
            </r-btn>
          </div>
        </r-col>
        <r-col class="col-12 h-center h-space-around flex-wrap"> </r-col>
      </r-row>
      <r-divider></r-divider>
      <r-row class="h-center mb-10">
        <r-col class="col-auto" v-for="(item, i) in honors" :key="i">
          <r-card
            class="image-holder-honor text-center br-md"
            :href="'/' + item['images'][0]"
            target="_blank"
          >
            <r-img
              class="h-center mb-2"
              :src="'/' + item['images'][0]"
              :alt="item['name']"
              width="150"
              height="190"
              hover-title
              hover-dark
              hover-zoom
              title-vc
            ></r-img>
          </r-card>
        </r-col>
      </r-row>
    </r-container>
    <r-container full-width class="copyright">
      <r-row class="h-center no-gutters">
        <r-col class="col-auto">
          {{ $t(["footer_copyright_msg", [new Date().getFullYear()]]) }}
        </r-col>
      </r-row>
    </r-container>
    <r-modal v-model="show" bottom full-width>
      <r-card class="pb-5">
        <about-us></about-us>
      </r-card>
    </r-modal>
  </div>
</template>

<script>
import aboutUs from "../views/index/about_us";

export default {
  name: "homeFooter",
  components: { aboutUs },
  data() {
    return {
      show: false,
      social_media: {},
      contact_us: {},
      honors: [],
    };
  },
  created() {
    this.get();
  },

  methods: {
    get_honors() {
      this.$axios.get("home/honors").then(({ data }) => {
        this.honors = data;
      });
    },
    get() {
      this.$axios
        .get("home/settings/multi/contact_us,social_media,law_order")
        .then(({ data }) => {
          for (let i = 0; i < data.length; i++) {
            if (data[i]["name"] === "law_order") {
              this.$r.store["law_order"] = data[i]["value"];
            } else {
              this[data[i]["name"]] = data[i]["value"];
            }
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~renusify/style/include";
@import "~renusify/components/form/text-editor/style";

.home-footer {
  background: #eee;
  color: black;

  .logo-footer {
    cursor: pointer;
  }

  .copyright {
    background-color: black;
    color: white;
  }

  .image-holder {
    width: 150px;
    height: 150px;
    background-color: white;
    border-radius: 8px;
    border: 1px solid #9a9c9e;
    margin-bottom: 10px;
  }

  .image-holder-honor {
    width: 150px;
    height: 190px;
    background-color: white;
    color: dimgrey;
    border-radius: 8px;
    border: 1px solid #9a9c9e;
    margin-bottom: 10px;
    cursor: pointer;
  }
}
</style>

<template>
  <r-card class="order-cart">
    <r-container full-width class="pb-0">
      <template v-if="size > 0">
        <r-row class="v-center h-center" v-for="(item, id) in cart" :key="id">
          <r-col class="col-6 sm-2">
            <r-img
              :src="'/' + item.images[0]"
              :alt="item.title"
              auto-size
            ></r-img>
          </r-col>
          <r-col class="col-12 sm-10">
            <r-row class="v-baseline">
              <r-col class="col-6 md-4">
                <div>
                  {{ item.title }}
                </div>
                <div class="caption">
                  {{ $t(["weight_unit", [$n(item.weight)]]) }}
                </div>
              </r-col>
              <r-col class="col-6 md-3">
                <span
                  v-if="item.off > 0"
                  class="price-disabled color-disabled-text"
                  >{{ $n(item.price * item.count_cart) }}</span
                >
                <span v-if="item.off > 0">-</span>
                <span class="text-no-wrap">
                  {{ $n(price_off(item) * item.count_cart) }}
                  {{ $t("unit_" + $r.store.unit) }}
                </span>
              </r-col>
              <r-col class="col-6 md-3">
                <r-number
                  v-model="$r.store.cart[id].count_cart"
                  :max="item.count"
                  :min="1"
                ></r-number>
              </r-col>
              <r-col class="col-6 md-2">
                <r-btn
                  icon
                  text
                  class="color-error-text"
                  @click.prevent="del(id)"
                >
                  <r-icon class="mdi-delete"></r-icon>
                </r-btn>
              </r-col>
            </r-row>
          </r-col>
          <r-col class="col-12">
            <r-divider></r-divider>
          </r-col>
        </r-row>
      </template>
      <r-row v-else>
        <r-col class="col-12 text-center display-4">
          {{ $t("cart_empty") }}
        </r-col>
      </r-row>
      <r-row v-if="showAction" class="h-end background-one">
        <r-col class="col-auto">
          <r-btn outlined class="color-warning-text" @click.prevent="go()">
            {{ $t("see_cart") }}
          </r-btn>
        </r-col>
        <r-col class="col-auto">
          <r-btn
            outlined
            class="color-info-text"
            @click.prevent="$r.store['showCart'] = false"
          >
            {{ $t("continue_shopping") }}
          </r-btn>
        </r-col>
      </r-row>
    </r-container>
  </r-card>
</template>

<script>
export default {
  name: "orderCard",
  props: {
    showAction: Boolean,
  },
  computed: {
    size() {
      return this.$helper.size(this.cart);
    },
    cart() {
      this.$storage.set("carts", this.$r.store.cart);
      return this.$r.store.cart;
    },
  },
  methods: {
    price_off(item) {
      if (item.off) {
        return (item.price * (100 - item.off)) / 100;
      }
      return item.price;
    },
    del(id) {
      delete this.$r.store.cart[id];
      this.$storage.set("carts", this.$r.store.cart);
    },
    go() {
      this.$r.store["showCart"] = false;
    },
  },
};
</script>

<style lang="scss">
@import "~renusify/style/include";

.order-cart {
  .price-disabled {
    text-decoration: line-through;
  }
}
</style>
